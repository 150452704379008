html{
    background-color: #f2f2f7;
    height: 100%;
}

body{
    height: 100%;
    display: flex;
    /* margin: 0; */
}
#root{
    /* height: auto; */
    width: 100%;
}
.Viewers,#viewer,.main{
    height: 100%;
}

.Title{
    color: #262626;
    text-decoration: none;
}
.notFound{
    margin: 30px auto auto;
    display: flex;
    width: 96%;
    height: 40%;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
footer{
    position: absolute;
    bottom: 0;
    display: flex;
    margin: auto;
}