.footer{
    width: 100%;
}
.footer .copyright_wrap{
    width: 100%;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
}
.footer .copyright_wrap .copyright{
    text-align: center;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.55);
}
.copyright a{
    color: inherit;
    text-decoration: inherit;
}
.copyright a:hover{
    color: #444;
}